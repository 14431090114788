import { Lead, ApiError } from 'shared/types';

import BaseEntity from '../BaseEntity';

/*
В методах класса не должно быть ReactNode вызовов!
Они могут использоваться на сервере!
 */
class Form extends BaseEntity {
  protected FORMS: string = 'forms';
  protected FORMPROP: string = 'form-props';
  protected FORMPROPDATA: string = 'form-prop-data';
  protected FORMTEMPLATES: string = 'form-templates';
  protected FORMTEMPLATEPROPS: string = 'form-template-props';
  protected FORMPROPTYPES: string = 'form-prop-types';

  async getForms(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.FORMS, filter);
    if (err) return { err: { message: (err as any).response.data.message } };
    return data;
  }
  async getPropType(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.FORMPROPTYPES, filter);
    if (err) return { err: { message: (err as any).response.data.message } };
    return data;
  }
  async postForms(filter: {}): Promise<any> {
    const { err, data } = await this.post(this.FORMPROP, filter);
    if (err) return { err: { message: (err as any).response.data.message } };
    return data;
  }

  async updateForms(filter: {}): Promise<any> {
    const { err, data } = await this.put(this.FORMS, filter);
    if (err) return { err: { message: (err as any).response.data.message } };
    return data;
  }

  async removeForms(params?: any) {
    this.del(this.FORMS, {});
  }

  async getFormProps(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.FORMPROP, filter);
    if (err) return { err: { message: (err as any).response.data.message } };
    return data;
  }

  async postFormProps(filter: {}): Promise<any> {
    const { err, data } = await this.post(this.FORMPROP, filter);
    if (err) return { err: { message: (err as any).response.data.message } };
    return data;
  }

  async updateFormProps(filter: {}): Promise<any> {
    const { err, data } = await this.put(this.FORMPROP, filter);
    if (err) return { err: { message: (err as any).response.data.message } };
    return data;
  }

  async removeFormProps(params?: any) {
    this.del(this.FORMPROP, {});
  }

  async getFormPropData(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.FORMPROPDATA, filter);
    if (err) return { err: { message: (err as any).response.data.message } };
    return data;
  }

  async postFormPropData(filter: {}): Promise<any> {
    const { err, data } = await this.post(this.FORMPROPDATA, filter);
    if (err) return { err: { message: (err as any).response.data.message } };
    return data;
  }

  async updateFormPropData(filter: {}): Promise<any> {
    const { err, data } = await this.put(this.FORMPROPDATA, filter);
    if (err) return { err: { message: (err as any).response.data.message } };
    return data;
  }

  async removeFormPropData(params?: any) {
    this.del(this.FORMPROPDATA, {});
  }

  async getFormTemplate(filter: {}) {
    const { err, data } = await this.get(this.FORMTEMPLATES, filter);
    if (err) return { err: { message: (err as any).response.data.message } };
    return data;
  }

  async postFormTemplates(filter: {}) {
    const { err, data } = await this.post(this.FORMTEMPLATES, filter);
    if (err) return { err: { message: (err as any).response.data.message } };
    return data;
  }

  async updateFormTemplates(filter: {}) {
    const { err, data } = await this.put(this.FORMTEMPLATES, filter);
    if (err) return { err: { message: (err as any).response.data.message } };
    return data;
  }

  async removeFormTemplates(params?: any) {
    this.del(this.FORMPROPDATA, {});
  }

  async getFormTemplatesProps(filter: {}) {
    const { err, data } = await this.get(this.FORMTEMPLATEPROPS, filter);
    if (err) return { err: { message: (err as any).response.data.message } };
    return data;
  }

  async postFormTemplatesPost(filter: {}) {
    const { err, data } = await this.post(this.FORMTEMPLATEPROPS, filter);
    if (err) return { err: { message: (err as any).response.data.message } };
    return data;
  }

  async updateFormTemplatesPost(filter: {}) {
    const { err, data } = await this.put(this.FORMTEMPLATEPROPS, filter);
    if (err) return { err: { message: (err as any).response.data.message } };
    return data;
  }

  async removeFormTemplatesPost(params?: any) {
    this.del(this.FORMTEMPLATEPROPS, params);
  }
}

export default Form;
